<template>
  <b-overlay
    :show="showLoading"
    variant="dark"
    :opacity="0.25"
    rounded="sm"
  >
    <b-card
      no-body
      class="p-3"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer ref="formRef">
            <b-form @submit.prevent>
              <b-row>
                <!-- Cashbank Account Field -->
                <b-col cols="12" sm="3">
                  <b-form-group
                    :label="$t(`apps.transactionsCashbank.singular.transferFrom`)"
                    label-for="account-id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="account-id"
                      rules="required"
                    >
                      <v-select
                        v-if="companyActive.plan_id > 2"
                        id="cashbank-account"
                        ref="firstFocusSelectInput"
                        v-model="form.cashbankId"
                        :options="LOV.cashbankCodeAccounts"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <v-select
                        v-else
                        id="account-id"
                        class="select-size-sm"
                        ref="firstFocusSelectInput"
                        v-model="form.accountId"
                        :options="LOV.cashBankAccounts"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="3">
                  <b-form-group
                    :label="$t(`apps.transactionsCashbank.singular.transferTo`)"
                    label-for="transfer-to"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="transfer-to"
                      rules="required"
                    >
                      <v-select
                        v-if="companyActive.plan_id > 2"
                        id="cashbank-account"
                        ref="firstFocusSelectInput"
                        v-model="form.cashbankIdTo"
                        :options="LOV.toCashbankCodeAccounts"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <v-select
                        v-else
                        id="transfer-to"
                        class="select-size-sm"
                        v-model="form.transferTo"
                        :options="LOV.cashBankAccounts"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Transaction No Field -->
                <b-col cols="12" md="2">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.amount')"
                    label-for="code"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="amount"
                      rules="required"
                    >
                      <cleave
                        v-model.number="form.amount"
                        :state="errors.length > 0 ? false:null"
                        class="form-control text-right form-control-sm"
                        :options="numeric"
                        :disabled="actions.isPreview"
                        placeholder="10,000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Date Field -->
                <b-col cols="12" md="2">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.date')"
                    label-for="date"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date"
                      rules="required"
                    >
                      <flat-pickr
                        id="date"
                        :config="dateFormat"
                        v-model="form.date"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="form-control form-control-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Transaction No Field -->
                <b-col cols="12" md="2">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.code')"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      size="sm"
                      v-model="form.code"
                      placeholder="CI-2021020001"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- checklist  -->
              <b-row>
                <b-col md="3">
                    <b-form-group :label="$t('apps.masterDataContact.singular.isDroping')">
                      <b-form-checkbox
                        v-model="form.isDroping"
                        :disabled="actions.isPreview"
                        class="mr-0 custom-control-inline"
                      />
                      </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <!-- Description Field -->
                <b-col cols="12" md="8">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.notes')"
                    label-for="notes"
                  >
                    <b-form-textarea
                      id="notes"
                      size="sm"
                      v-model="form.remark"
                      rows="1"
                      :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
                <!-- Tags Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.tags')"
                    label-for="tags"
                  >
                    <v-select
                      v-model="form.tags"
                      id="tags"
                      class="select-size-sm"
                      label="name"
                      multiple
                      :options="LOV.tags"
                      :reduce="field => field.id"
                      :create-option="field => ({id: Date.now(), name: field})"
                      @option:created="pushTags"
                      :searchable="!actions.isPreview"
                      :selectable="option => !actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="my-1" v-if="form.isDroping">
                  <!-- month  -->
                <b-col cols="3" md="6">
                  <b-form-group
                    :label="$t('apps.reportBudget.singular.month')"
                    label-for="month"
                  >
                      <validation-provider
                                    #default="{ errors }"
                                    name="month"
                                    rules="required"
                            >
                          <v-select
                                    inputId="month"
                                    class="select-size-sm"
                                    v-model="form.month"
                                    :options="LOV.month"
                                    :reduce="field => field.value"
                                    :state="errors.length"
                                    :disabled="actions.isPreview"

                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                  </b-form-group>
                </b-col>

                  <!-- year  -->
                  <b-col cols="3" md="6">
                    <b-form-group
                      :label="$t('globalSingular.period')"
                      label-for="year"
                    >
                    <validation-provider
                                #default="{ errors }"
                                name="period"
                                rules="required"
                        >
                      <v-select
                                inputId="period"
                                class="select-size-sm"
                                v-model="form.year"
                                :options="LOV.year"
                                :state="errors.length"
                                :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

              </b-row>

              <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                  :to="{ name: 'apps-transactions-cashbank-list' }"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                  />
                  {{ $t('globalActions.backToList') }}
                </b-button>

                <b-button
                  v-if="actions.isPreview"
                  class=" mb-sm-1 mb-md-0"
                  variant="outline-danger"
                  @click="handleDelete"
                >
                  <feather-icon
                    icon="Trash2Icon"
                  />
                  {{ $t('globalActions.delete') }}
                </b-button>

                <b-button
                  v-if="!actions.isPreview"
                  variant="primary"
                  type="submit"
                  @click="handleSubmit"
                >
                  <feather-icon
                    icon="SaveIcon"
                  />
                  {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
                </b-button>

                <b-button
                  v-else
                  variant="outline-primary"
                  @click="changeToEdit"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                  {{ $t('globalActions.changeToEdit') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>


<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { onMounted, ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

import { formatDatepicker } from '@/utils/formatter'


import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import storestate from '@/store/index'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave,
  },


  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({url: 'transaction/cashbank', localeContextPath: 'apps.transactionsCashbank.singular.transaction', redirectPathName: 'apps-transactions-cashbank'})

    const LOV = ref({
      cashBankAccounts: [],
      cashbankCodeAccounts: [],
      toCashbankCodeAccounts: [],
      tags: [],
      year: [
         2021,
         2022,
         2023,
         2024
       ],
      month: [
            {
                label: 'January',
                value: 1
            },
            {
                label: 'February',
                value: 2
            },
            {
                label: 'March',
                value: 3
            },
            {
                label: 'April',
                value: 4
            },
            {
                label: 'May',
                value: 5
            },
            {
                label: 'June',
                value: 6
            },
            {
                label: 'July',
                value: 7
            },
            {
                label: 'Agust',
                value: 8
            },
            {
                label: 'September',
                value: 9
            },
            {
                label: 'October',
                value: 10
            },
            {
                label: 'November',
                value: 11
            },
            {
                label: 'December',
                value: 12
            }
       ],
    })

    const getCashBankAccount = async () => {
      LOV.value.cashBankAccounts = await get({url: 'master/accounts/child?account_subtype_code=CASHBANK'})
    }

    const getCashbankCode = async () => {
      LOV.value.cashbankCodeAccounts = await get({ url: 'value/mapping-cashbank-per-users'})
    }

    const getToCashbankCode = async () => {
      LOV.value.toCashbankCodeAccounts = await get({ url: 'master/cashbank'})
    }

    const getTags = async () => {
      LOV.value.tags = await get({url: 'value/tag'})
    }

    const tableColumns = ref([])

    const form = ref({
      accountId: '',
      cashbankId: '',
      transferTo: '',
      cashbankIdTo: '',
      isDroping: false,
      month:'',
      year: '',
      code: '',
      amount: '',
      date: formatDatepicker(Date.now()),
      tags: [],
      remark: '',
      transactionTypeCode: 'TF'
    })
    const showLoading = ref(false)

    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      allowInput: true,
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year,  companyActive.closing_month, 1).getTime()))
        }
      ]
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    })

    // will get data to show the preview
    const getDataPreview = async () => {
      showLoading.value = true
      const dataById = await show()
      const data = await get({url: `transaction/cashbank?code=${dataById.code}`})

      const transferData = data.filter(transfer => transfer.is_transfer)[0]
      form.value.accountId = transferData.account_id
      if (companyActive.plan_id > 2) {
        form.value.cashbankId = transferData.cashbank_id
        form.value.cashbankIdTo = transferData.cashbank_id_to
      }
      form.value.transferTo = transferData.details[0].account_id
      form.value.code = transferData.code
      form.value.amount = parseInt(transferData.amount) * (-1)
      form.value.date = transferData.date
      form.value.tags = transferData.tags.map(tag => parseInt(tag.id))
      form.value.remark = transferData.remark
      form.value.isDroping = transferData.is_droping
      showLoading.value = false
    }

    onMounted(async () => {
      getCashBankAccount()
      getToCashbankCode()
      getTags()

      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      if (companyActive.plan_id > 2) {
        getCashbankCode()
      }
    })

    const {pushTagsToAPI} = usePushTags()
    const pushTags = async newTag => {
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = await pushTagsToAPI(newTag)
    }

    return {
      companyActive,
      pushTags,
      required,
      LOV,
      tableColumns,
      form,
      dateFormat,
      numeric,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      showLoading,
    }
  },


  methods: {
    async handleDelete () {
      this.showLoading = true
      await this.destroy(this.$swal)
        .finally(() => {
          this.showLoading = false
        })
    },

    async handleSubmit () {
      this.showLoading = true
      await this.store({
        $swal: this.$swal,
        data: this.form
      })
        .finally(() => {
          this.showLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
